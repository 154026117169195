import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import App from "./App";
import Header from "./components/header.jsx";
import Footer from "./components/footer";
import TemporaryDrawer from "./components/hmodal";
import LaunchApp from "./components/launchApp";
import Disclaimer from "./components/disclaimer";
import Conditions from "./components/Conditions";
import Privacy from "./components/privacy";
import NetworkChange from "./NetworkModal";
import Web3 from "web3";
// Privacy
// Disclaimer

export default function Main() {
  const [draweropen, setDrawerOpen] = useState(false);
  const [switchNetwork, setswitchNetwork] = useState(false);
  const web3 = new Web3(
    Web3.givenProvider ||
      "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
  );
  useEffect(() => {
    let chain = async () => {
      const chainid = await web3.eth.getChainId();
      if (chainid !== 1) {
        setswitchNetwork(true);
      }
    };
    chain();
  }, []);

  return (
    <div>
      <NetworkChange open={switchNetwork} setOpen={setswitchNetwork} />
      <TemporaryDrawer draweropen={draweropen} setDrawerOpen={setDrawerOpen} />
      <BrowserRouter>
        <Header setDrawerOpen={setDrawerOpen} />
        <Route exact path="/">
          <App />
        </Route>
        {/* <Route exact path="/launchApp">
          <LaunchApp />
        </Route> */}
        {/* <Route exact path="/disclamier">
          <Disclaimer />
        </Route> */}
        {/* <Route exact path="/condition">
          <Conditions />
        </Route> */}
        {/* <Route exact path="/privacy">
          <Privacy />
        </Route> */}
        <Footer />
      </BrowserRouter>
    </div>
  );
}
