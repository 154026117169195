import React, { useState } from "react";
import {
  Box,
  Container,
  Hidden,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
// import { Link } from "react-router-dom";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import Modal from "../modal";
import Logout from "../logoutModal";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
export default function Header({ setDrawerOpen }) {
  const [open, setopen] = useState(false);
  const matches = useMediaQuery("(max-width:960px)");
  const [open1, setopen1] = useState(false);
  const { account, active } = useWeb3React();
  const [scrol, setScrool] = useState(false);

  const chanedBackgrounColor = () => {
    if (window.scrollY >= 80) {
      setScrool(true);
    } else {
      setScrool(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", chanedBackgrounColor);
  }, []);

  return (
    <Box
      bgcolor={scrol ? "#000" : "#000"}
      position="fixed"
      left="0px"
      right="0px"
      zIndex="100"
    >
      <Modal open={open} setOpen={setopen} />
      <Logout open={open1} setOpen={setopen1} />
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexBasis="20%"
          >
            <Box>
              <a
                href="https://www.wolverinu.com/"
                target="_blank"
                style={{
                  color: "white",
                  textDecoration: "none",
                  display: "flex",
                }}
              >
                <img src="logo.svg" width="50px" height="60px" alt="" />
                <Box
                  style={{
                    display: "flex",
                    marginLeft: "5px",
                    WebkitBoxAlign: "center",
                    WebkitAlignItems: "center",
                    msFlexAlign: "center",
                    alignItems: "center",
                    WebkitAlignSelf: "center",
                    msFlexItemAlign: "center",
                    alignSelf: "center",
                    fontFamily: "'Proxima nova', sans-serif",
                    color: "#ffbf00",
                    fontSize: "20px",
                    fontWeight: 800,
                    letterSpacing: "1px",
                    textTransform: "capitalize",
                  }}
                >
                  Wolverinu
                </Box>
              </a>
            </Box>
            <Hidden smDown>
              {/* <Box>
                <Link
                  to="/launchApp"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Launch app
                </Link>
              </Box> */}
              <Box>
                <a
                  href="https://www.wolverinu.com/"
                  target="_blank"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Home
                </a>
              </Box>
              {/* <Box>
                <a
                  href="https://docs.Wolverinu.io/"
                  target="_blank"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  White paper
                </a>
              </Box> */}
            </Hidden>
          </Box>

          <Box>
            {!active ? (
              <button
                style={{
                  background: "linear-gradient(90deg, #ffe601, #ff8b03)",
                  boxShadow: "0px 0px 13px rgba(182, 0, 211, 0.7)",
                  borderRadius: "24px",
                  color: "#fff",
                  padding: matches ? "10px 10px" : "10px 40px",
                  fontSize: matches ? "12px" : "18px",
                  border: "none",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={() => setopen(true)}
              >
                Connect
              </button>
            ) : (
              <button
                style={{
                  background: "linear-gradient(90deg, #ffe601, #ff8b03)",
                  boxShadow: "0px 0px 13px rgba(182, 0, 211, 0.7)",
                  borderRadius: "24px",
                  color: "#000000",
                  padding: matches ? "10px 10px" : "10px 40px",
                  fontSize: matches ? "12px" : "18px",
                  border: "none",
                  marginRight: "10px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
                onClick={() => setopen1(true)}
              >
                {account?.slice(0, 6) + "..." + account?.slice(-4)}
              </button>
            )}
          </Box>
          {/* <Hidden smDown>
            <IconButton onClick={() => setDrawerOpen(true)}>
              <MenuOpenIcon
                style={{ fontSize: "38px", cursor: "pointer", color: "#fff" }}
              />
            </IconButton>
          </Hidden> */}
        </Box>
      </Container>
    </Box>
  );
}
