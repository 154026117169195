import { formatUnits } from "@ethersproject/units";
import { Box, Container, Grid, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import homeBg from "../images/bgover.svg";

export default function Home({ totalStakers, totalstaked, Apy }) {
  const matches = useMediaQuery("(max-width:760px)");

  return (
    <Box
      style={{
        background: `url(${homeBg}),linear-gradient(90deg, #ffe601, #ff8b03)`,
        // background: `url(${homeBg})`,
        backgroundSize: "contain",
        backgroundPosition: "10% 100%",
        backgroundRepeat: "no-repeat",
        clipPath: "polygon(0 1%, 100% 0%, 100% 88%, 0% 100%)",
      }}
      pb={26}
      pt={10}
    >
      <Container maxWidth="lg">
        <Grid
          container
          justifyContent="space-between"
          style={{ alignItems: "center" }}
        >
          <Grid item xs={12} md={3}>
            {/* <Box
              component="h3"
              fontSize={{ xs: "30px", sm: "40px" }}
              fontWeight={700}
              mt={8}
            >
              Wolverinu Staking Dashboard
            </Box> */}

            <Box
              width="60%"
              position="relative"
              top="0rem"
              right="0rem"
              left="0rem"
            >
              <img
                src="logowar.png"
                style={{
                  height: matches ? "45rem" : "40rem",
                  position: "absolute",
                  top: matches ? "-5rem" : "-13rem",
                }}
              />
            </Box>

            {/* <Box>
              <a href="#staking">
                <button
                  style={{
                    background:
                      "linear-gradient(180deg, #D90EE8 0%, #1B7DAB 143.1%)",
                    boxShadow: "0px 0px 17px rgba(182, 0, 211, 0.8)",
                    border: "none",
                    color: "#fff",
                    padding: "15px 60px",
                    fontSize: "25px",
                    borderRadius: "12px",
                    cursor: "pointer",
                  }}
                >
                  Stake Wolverinu
                </button>
              </a>
            </Box> */}
            {/* <Box
              component="h3"
              color="#fff"
              display="flex"
              alignItems="center"
              fontSize={{ xs: "18px", sm: "18px" }}
              fontWeight={400}
            >
              Powered by{" "}
              <Box mx={1}>
                {" "}
                <img src="bsc.png" width="40px" alt="" />
              </Box>{" "}
              BSC
            </Box> */}
          </Grid>
          <Grid item xs={12} md={5} style={{ position: "relative" }}>
            <Box
              color={matches ? "#ffff" : "#000000"}
              component="h3"
              fontSize={{ xs: "30px", sm: "2.2rem" }}
              fontWeight="900"
              mt={8}
              textAlign="center"
            >
              Wolverinu Staking Dashboard
            </Box>
            {/* <Box
              borderRadius="24px"
              p={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              border="2px solid #B600D3"
              position="absolute"
              width="100%"
              height="100%"
              zIndex="1"
              top="3%"
              right="-2%"
            ></Box> */}
            <Box
              style={{
                background:
                  "linear-gradient(270.54deg, #100B12 -2.11%, #18141A 103.66%)",
              }}
              borderRadius="24px"
              // p={2}
              // display="flex"
              // justifyContent="space-between"
              alignItems="center"
              border="2px solid #FFE201"
              position="relative"
              zIndex="2"
              // py={10}
            >
              <Box>
                <Box
                  component="h3"
                  fontSize={{ xs: "18px", sm: "30px" }}
                  color="#FFE201"
                  textAlign="center"
                  pr={6}
                >
                  Wolverinu staked:
                </Box>
                <Box
                  textAlign="center"
                  component="h3"
                  fontSize={{ xs: "18px", sm: "21px" }}
                  color="#FF9602"
                >
                  {
                    totalstaked.toLocaleString()
                    //  parseFloat(formatUnits(totalstaked, 9)).toFixed(2)
                  }{" "}
                  <span style={{ fontSize: "12px", fontWeight: 300 }}>
                    Wolverinu
                  </span>
                </Box>
              </Box>
              <Box>
                <Box
                  color="#FFE201"
                  component="h3"
                  fontSize={{ xs: "18px", sm: "30px" }}
                  textAlign="center"
                  pr={6}
                >
                  Wolverinu stakers:
                </Box>
                <Box
                  textAlign="center"
                  component="h3"
                  fontSize={{ xs: "18px", sm: "24px" }}
                  color="#FF9602"
                >
                  {totalStakers}{" "}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* <Grid align="right">
          <Box
            mt={3}
            sx={{
              marginRight: "1rem",
              background: "#ECD03E",
              zIndex: "1",
              position: "relative",
              textAlign: "center",
              width: matches ? "100%" : "40%",
            }}
          >
            <Box
              sx={{
                color: "#000000",
                padding: "1rem",
                fontSize: ["1rem", "1.5rem"],
                fontWeight: "700",
              }}
            >
              SCROLL DOWN TO THE CALCULATOR
            </Box>
          </Box>
        </Grid> */}
      </Container>
    </Box>
  );
}
