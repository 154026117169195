import { Box, Container, Grid, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import poolCard from "../images/poolcard.png";
import { tokenAddress, tokenAddressAdmantium } from "../utils/environment";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import RedditIcon from "@material-ui/icons/Reddit";
// tokenAddress

export default function Footer() {
  const matches = useMediaQuery("(max-width:960px)");
  return (
    <>
      <Box position="relative" mt={20} pt={20} pb={10} overflow="hidden">
        <Box
          position="absolute"
          top="-53%"
          left="0%"
          height="70%"
          width="100%"
          right="0%"
          zIndex="-1"
        >
          {/* <img width="100%" src="pic4.png" alt="" /> */}
        </Box>
        <Container maxWidth="lg">
          <Grid item align="center">
            <img src="footerlogo.png" width="50%" height="auto" />
          </Grid>
          <Grid container style={{ justifyContent: "center" }}>
            <Grid
              item
              xs={12}
              spacing={6}
              align="center"

              // style={{ alignItems: "center" }}
            >
              <Box component="h3" fontSize={{ xs: "20px", sm: "23px" }}>
                <img src="logo.svg" style={{ height: "100px" }} />
              </Box>
              {/* <Link style={{ textDecoration: "none", color: "#fff" }}> */}
              <Box
                component="h3"
                fontWeight={400}
                fontSize={{ xs: "16px", sm: "20px" }}
              >
                <Box my={3}>
                  <a
                    href="https://twitter.com/wolverinutoken"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "10px" }}
                  >
                    <TwitterIcon
                      fontSize="large"
                      style={{ color: "#D5A206" }}
                    />
                  </a>
                  <a
                    href="https://t.me/wolverinu"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "10px" }}
                  >
                    <TelegramIcon
                      fontSize="large"
                      style={{ color: "#D5A206" }}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/wolverinuofficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "10px" }}
                  >
                    <InstagramIcon
                      fontSize="large"
                      style={{ color: "#D5A206" }}
                    />
                  </a>
                  <a
                    href="https://www.reddit.com/r/WOLVERINU/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "10px" }}
                  >
                    <RedditIcon fontSize="large" style={{ color: "#D5A206" }} />
                  </a>

                  <a
                    href="https://discord.com/invite/gxyWurnGpX"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "10px" }}
                  >
                    <img
                      src="https://global-uploads.webflow.com/6191e507f3028a2aa81f9749/61994d8278f2211841d55db7_Frame%20(5).svg"
                      alt=""
                      style={{ height: "30px" }}
                    />
                  </a>
                </Box>
              </Box>
              {/* </Link> */}
              {/* <Link style={{ textDecoration: "none", color: "#fff" }}> */}
              {/* <Box
            
                fontSize={{ xs: "15px", sm: "17px" }}
              
              >
                Copyright © 2021 |Wolverinu Limited
              </Box> */}
              {/* </Link> */}
              {/* <Link style={{ textDecoration: "none", color: "#fff" }}>
                <Box
                  component="h3"
                  fontWeight={400}
                  fontSize={{ xs: "16px", sm: "20px" }}
                >
                  Documentation
                </Box>
              </Link> */}
            </Grid>
            <Grid
              item
              xs={12}
              spacing={6}
              // align="right"
              // float="right"
            >
              <Box align="center">
                <Box
                  component="h3"
                  fontSize={{ xs: "20px", sm: "23px" }}
                  color="#FF9602"
                >
                  Wolverinu Address
                </Box>
                <Box fontSize={{ xs: "15px", sm: "17px" }} color="#FFE201">
                  0xca7b3ba66556C4Da2E2A9AFeF9C64F909A59430a
                </Box>
                <Box
                  component="h3"
                  fontSize={{ xs: "20px", sm: "23px" }}
                  color="#FF9602"
                >
                  ADAMANTIUM Address
                </Box>
                <Box fontSize={{ xs: "15px", sm: "17px" }} color="#FFE201">
                  {/* 0x5dD17EAaeeCE9f5E6CC27aF42861B8769C82E447 */}
                  {tokenAddressAdmantium}
                </Box>
                <hr />
                <Box>
                  This site contains material that may be copyrighted with use
                  of which has not always been specifically authorized by the
                  copyright owner. All materials contained in this site are
                  presented as a transformative works in an act of parody, and
                  the Wolverinu artist original interpretations. Wolverinu does
                  not claim any partnership, endorsement, co-ownership, or
                  sponsorship with any other entity. The existence and actions
                  of Wolverinu do not adversely impact the market for any
                  property owners. Furthermore, Wolverinu makes all content
                  within available for the purpose of education in the ever
                  growing Blockchain environment. These actions constitute for a
                  “fair use” of any such material contained within under Title
                  17, Section 107 of the U.S. Copyright Law.
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* <Box
        bgcolor="#0B020F"
        p={4}
        fontSize={{ xs: "16px", sm: "20px" }}
        textAlign="center"
      >
        Copyright © 2021 |Wolverinu Limited
      </Box> */}
    </>
  );
}
