import { Box, Container, Grid, useMediaQuery } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Web3 from "web3";
import {
  useStakingContract,
  useStakingContract1,
  useTokenContract,
  useTokenContract1,
} from "../hooks";
import poolCard from "../images/poolcard.png";
import Slider from "@material-ui/core/Slider";
import { stakingAddress } from "../utils/environment";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Loading from "./loading";
import { parseUnits, formatUnits } from "@ethersproject/units";
import { makeStyles } from "@material-ui/core/styles";
import { toPlainString } from "../util";
const web3 = new Web3(Web3.givenProvider);

export default function Tiers({ init, init1 }) {
  const matches = useMediaQuery("(max-width:960px)");
  const { account, active } = useWeb3React();

  const StakingContract = useStakingContract();
  const StakingContract1 = useStakingContract1();
  const tokenContract1 = useTokenContract1();
  const [index, setindex] = useState(0);
  const [amount, setamount] = useState("");
  const tokenContract = useTokenContract();
  const [loading, setloading] = useState(false);
  const [bonus, setbonus] = useState(0);
  const [stakingValue, setStakingValue] = useState(0);
  const [percentageslider, sliderPercentage] = useState(0);
  const [decimal, setDecimal] = useState(0);
  const [balance, setBalance] = useState(0);
  const [percentValue, setPercentValue] = useState(0);
  const [wolVPercentValue, setWlPercentValue] = useState(0);
  const [durationone, setDurationOne] = React.useState(0);
  const [durationtwo, setDurationTwo] = React.useState(0);
  const [durationthree, setDurationThree] = React.useState(0);
  const [durationfour, setDurationFour] = React.useState(0);
  const [dynamicduration, setDynamicDuration] = React.useState(0);
  const [percentages, setPercenttages] = React.useState(0);

  const stakeHandler = async () => {
    if (!account) {
      toast.error("Error! Please connect your wallet.");
    } else if (+percentValue <= 0) {
      toast.error("Error! Please enter amount.");
    } else {
      try {
        setloading(true);
        const tx = await tokenContract.approve(stakingAddress, percentValue);
        await tx.wait();
        const tx1 = await StakingContract.stake(percentValue, index.toString());
        await tx1.wait();
        toast.success("Success! Transaction Confirmed.");
        setloading(false);
        init();
        init1();
        window.location.reload();
      } catch (err) {
        if (err?.data?.message) {
          toast.error(err?.data?.message);
        } else {
          toast.error(err?.message);
        }
        setloading(false);
      }
    }
  };

  // console.log(percentValue, "percentValue===>");

  // useEffect(() => {
  //   const init = async () => {
  //     if (tokenContract1 && StakingContract1) {
  //       const dec = await tokenContract1?.methods.decimals().call();
  //       let am = parseUnits(amount, dec);
  //       setStakingValue(am);
  //       const percent = await StakingContract1?.methods
  //         .calculateRewards(am.toString(), index)
  //         .call();
  //       setbonus(+percent / Math.pow(10, dec));
  //     } else {
  //       const dec = await tokenContract?.decimals();
  //       let am = parseUnits(amount, dec);
  //       setStakingValue(am);
  //       const percent = await StakingContract?.calculateRewards(
  //         am.toString(),
  //         index
  //       );
  //       setbonus(+percent / Math.pow(10, dec));
  //     }
  //   };
  //   if (StakingContract1 && StakingContract1 && +amount > 0) {
  //     init();
  //   }
  // }, [
  //   StakingContract1,
  //   tokenContract1,
  //   StakingContract,
  //   tokenContract,
  //   amount,
  //   index,
  // ]);
  React.useEffect(() => {
    if (account && +percentageslider > 0 && decimal > 0 && +balance > 0) {
      (async () => {
        try {
          setloading(true);
          let amount = (percentageslider * +balance) / 100;
          let plainstring = toPlainString(amount);
          setPercentValue(plainstring);
          let am = parseUnits(plainstring, decimal);
          const percent = await StakingContract?.calculateRewards(am, index);
          setbonus(+percent / Math.pow(10, decimal));
          setloading(false);
        } catch (e) {
          console.log(e, "e===>");
        }
      })();
    }
  }, [percentageslider, index, StakingContract]);

  React.useEffect(() => {
    if (StakingContract && tokenContract) {
      (async () => {
        try {
          const dec = await tokenContract?.decimals();
          const balan = await tokenContract.balanceOf(account);
          setBalance(+balan);
          setDecimal(dec);
        } catch (e) {
          console.log(e, "e===>");
        }
      })();
    }
  }, [tokenContract, StakingContract, account]);

  React.useEffect(() => {
    let wolverinValue = formatUnits(percentValue, decimal);
    // console.log(wolverinValue, "wolverinValue===>", percentValue);
    setWlPercentValue(wolverinValue);
  }, [percentValue, index]);

  // console.log(wolVPercentValue, "percentValue===>");

  React.useEffect(() => {
    if (StakingContract1) {
      (async () => {
        try {
          const durationone = await StakingContract1.methods
            .durations(0)
            .call();
          setDurationOne(durationone);
          const durationtwo = await StakingContract1.methods
            .durations(1)
            .call();
          setDurationTwo(durationtwo);
          const durationthree = await StakingContract1.methods
            .durations(2)
            .call();
          setDurationThree(durationthree);
          const durationFour = await StakingContract1.methods
            .durations(3)
            .call();
          setDurationFour(durationFour);

          const percentage = await StakingContract1.methods
            .percentages(index)
            .call();
          setPercenttages(percentage);

          const dynamicsduration = await StakingContract1.methods
            .durations(index)
            .call();
          let vd =
            +dynamicsduration >= 86400
              ? +dynamicsduration / 86400
              : dynamicsduration;
          setDynamicDuration(vd);
        } catch (e) {
          console.log(e, "e===>");
        }
      })();
    }
  }, [StakingContract1, index]);

  function valuetext(value) {
    return `${value}`;
  }

  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  let adamantium = (+bonus / Math.pow(10, +decimal)).toFixed(0);

  console.log(percentages, "percentages===>");

  return (
    <Box position="relative" id="staking">
      <Loading loading={loading} />
      <Box position="absolute" left="0%" top="-30%" zIndex="-1"></Box>
      <Box position="absolute" right="0%" top="0%" zIndex="-1"></Box>
      <Box
        position="absolute"
        bottom="-80%"
        left="0%"
        right="0%"
        zIndex="-1"
      ></Box>
      <Container maxWidth="lg">
        <Box
          borderRadius={{ xs: "20px", sm: "59px" }}
          style={{
            backgroundImage: "linear-gradient(225deg, #034282, #ff8b03)",
          }}
          align="cneter"
          width={{ xs: "100%", sm: "100%", md: "50%" }}
          mx="auto"
          mt={10}
          p={{ xs: 1, sm: 3 }}
          boxShadow="0px 0px 10px rgba(247, 232, 45, 0.8)"
        >
          <Box>
            <Box fontSize={[25, 30]} p={4} textAlign="center">
              We are currently working on a Staking V2 and have temporarily
              disabled staking. You are still able to claim your tokens at the
              bottom of this page!
            </Box>
          </Box>
          {/* <Box
            component="h3"
            textAlign="center"
            fontSize={{ xs: "1.3rem", sm: "2rem" }}
            color="#FFE201"
          >
            $Wolverinu Calculator
          </Box>
          <Box
            component="h3"
            textAlign="center"
            fontSize={{ xs: ".8rem", sm: "1rem" }}
            fontWeight={500}
            width="80%"
            mx="auto"
            my={2}
            color="#FF9602"
          >
            Calculate your $Wolverinu depending on the amount of staked tokens
            and your lock time.
          </Box>
          <Box bgcolor="#120D13" width="auto" mx="auto" p={0.2} my={0}>
            <Box p={0.5}>
              <Box bgcolor="#101010" width="100%" p={1}>
                <Box fontWeight={400} textAlign="center" fontSize="16px">
                  Wolverinu
                </Box>
                <Box fontSize={{ xs: "1.2rem", sm: "2rem" }}>
                  {wolVPercentValue ? wolVPercentValue.toLocaleString({}) : 0}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            component="h3"
            textAlign="center"
            fontSize={{ xs: ".8rem", sm: "1rem" }}
            fontWeight={500}
            width="80%"
            mx="auto"
            color="#FF9602"
          >
            Slide the scrubber to easily enter the amount of Wolverinu you want
            to stake.
          </Box>

          <Box mt={0} p={2}>
            <Slider
              style={{
                color: "#F6761A",
              }}
              defaultValue={0}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={2}
              marks={marks}
              min={0}
              max={100}
              value={percentageslider}
              onChange={(e, value) => sliderPercentage(value)}
              valueLabelDisplay="on"
            />
          </Box>

          <Box
            component="h3"
            align="center"
            fontSize={{ xs: "1.1rem", sm: "1.5rem" }}
            my={0}
            color="#183141"
          >
            +
          </Box>
          <Box
            bgcolor="#161117"
            width="80%"
            mx="auto"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            p={1}
            my={0}
          >
            <Box
              fontWeight={400}
              textAlign="center"
              fontSize={{ xs: "11px", sm: "15px" }}
              my={0}
            >
              Lock tokens for
            </Box>

            <Box
              fontWeight={400}
              textAlign="center"
              bgcolor={index === 0 ? "#FF9602" : "transparent"}
              border={index === 0 ? "2px solid #FF9602" : "2px solid #FF9602"}
              p={1}
              borderRadius="10px"
              style={{ cursor: "pointer" }}
              onClick={() => setindex(0)}
              my={2}
              ml={3}
            >
              {+durationone >= 86400 ? +durationone / 86400 : durationone} days
            </Box>
            <Box
              fontWeight={400}
              textAlign="center"
              bgcolor={index === 1 ? "#FF9602" : "transparent"}
              border={index === 1 ? "2px solid #FF9602" : "2px solid #FF9602"}
              p={1}
              borderRadius="10px"
              style={{ cursor: "pointer" }}
              onClick={() => setindex(1)}
              my={2}
              ml={3}
            >
              {+durationtwo >= 86400 ? +durationtwo / 86400 : durationtwo} days
            </Box>
            <Box
              fontWeight={400}
              bgcolor={index === 2 ? "#FF9602" : "transparent"}
              border={index === 2 ? "2px solid #FF9602" : "2px solid #FF9602"}
              p={1}
              borderRadius="10px"
              style={{ cursor: "pointer" }}
              onClick={() => setindex(2)}
              textAlign="center"
              my={2}
              ml={3}
            >
              {+durationthree >= 86400 ? +durationthree / 86400 : durationthree}{" "}
              days
            </Box>
            <Box
              fontWeight={400}
              bgcolor={index === 3 ? "#FF9602" : "transparent"}
              border={index === 3 ? "2px solid #FF9602" : "2px solid #FF9602"}
              p={1}
              borderRadius="10px"
              style={{ cursor: "pointer" }}
              onClick={() => setindex(3)}
              textAlign="center"
              my={2}
              ml={3}
            >
              {+durationfour >= 86400 ? +durationfour / 86400 : durationfour}
              days
            </Box>
          </Box>
          <Box align="center" my={1}>
            <ArrowDownwardIcon style={{ color: "#FFE201" }} fontSize="large" />
          </Box>

          <Box
            component="h3"
            fontWeight={400}
            fontSize={{ xs: "18px", sm: "30px" }}
            textAlign="center"
            my={0}
            ml={3}
          >
            {adamantium.toLocaleString({})}{" "}
            <span style={{ fontSize: "14px" }}>$ADAMANTIUM </span>
          </Box>
          <Box
            component="h3"
            fontWeight={400}
            fontSize={{ xs: "20", sm: "25px" }}
            textAlign="center"
            my={0}
            ml={3}
          >
            {percentages
              ? parseInt(
                  ((percentages / dynamicduration) * 365) / 1000
                ).toFixed(0)
              : 0}
            <span style={{ fontSize: "14px" }}>%APY </span>
            <span style={{ fontSize: "25px" }}>
              /{percentages ? parseFloat(+percentages / 1000).toFixed(2) : 0}
              <span style={{ fontSize: "14px" }}>% gains</span>
            </span>
          </Box>
          <Box
            component="h3"
            fontSize={{ xs: "10px", sm: "15px" }}
            textAlign="center"
            color="#fff"
            p={0}
            fontWeight={400}
          >
            locked until{" "}
            {moment()
              .add(
                index == 0 ? 15 : index == 1 ? 30 : index == 2 ? 60 : 90,
                "days"
              )
              .format("MM/DD/YYYY hh:mma")}
          </Box>
          <Box align="center" my={2}>
            <button
              style={{
                background: "linear-gradient(90deg, #ffe601, #ff8b03)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                border: "none",
                color: "#fff",
                padding: "10px 40px",
                fontSize: matches ? "15px" : "20px",
                borderRadius: "18px",
                width: "90%",
                cursor: "pointer",
              }}
              onClick={stakeHandler}
            >
              {active ? "Stake" : "Connect Wallet"}
            </button>
          </Box> */}
        </Box>
      </Container>
    </Box>
  );
}
