import { formatUnits } from "@ethersproject/units";
import {
  Box,
  Container,
  Grid,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { useStakingContract, useTokenContract } from "../hooks";
import poolCard from "../images/poolcard.png";
export default function Summary({
  staked,
  unstaked,
  withdrwan,
  reward,
  currentStaked,
  earned,
  balance,
}) {
  const matches = useMediaQuery("(max-width:960px)");

  return (
    <Box
      position="relative"
      style={{
        clipPath: "polygon(1% 0, 100% 8%, 100% 93%, 0% 100%)",

        backgroundImage: "linear-gradient(41deg, #ded100, #ff8b03)",
      }}
      pb={20}
      mt={5}
      pt={1}
    >
      <Container maxWidth="lg">
        <Box
          component="h3"
          fontSize={{ xs: "30px", sm: "50px" }}
          textAlign="center"
          color="#000000"
          mt={10}
          mb={13}
          pt={6}
        >
          Your Statistics
        </Box>
        <Box
          // component="h3"
          borderRadius={{ xs: "20px", sm: "59px" }}
          align="cneter"
          width={{ xs: "100%", sm: "100%", md: "80%" }}
          mx="auto"
          mt={10}
          p={{ xs: 2, sm: 2 }}
          boxShadow=" 0px 0px 23px 2px #000000"
          style={
            {
              // background:
              // "linear-gradient(271.8deg, #161117 1.39%, #1C161E 98.41%)",
            }
          }
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Box
              width={{ xs: "100%", sm: "45%" }}
              height="405px"
              mx="auto"
              borderRadius="20px"
              p={1}
              my={2}
            >
              <Box
                bgcolor="#000000"
                borderRadius="20px"
                width="100%"
                height="45%"
                p={2}
                mb={2}
              >
                <Box
                  fontWeight={700}
                  color="#FF9602"
                  fontSize={{ xs: "18px", sm: "22px" }}
                  textAlign="center"
                >
                  ADAMANTIUM Earned
                </Box>
                <Box
                  color="#FFE201"
                  fontWeight={900}
                  pt={2}
                  fontSize={{ xs: "18px", sm: "22px" }}
                  textAlign="center"
                >
                  {parseInt(+earned).toFixed(2)}
                </Box>
              </Box>
              <Box
                bgcolor="#000000"
                height="50%"
                borderRadius="20px"
                width="100%"
                // display="flex"
                alignItems="center"
                // justifyContent="space-between"
                p={2}
              >
                <Box
                  fontWeight={700}
                  color="#FF9602"
                  fontSize={{ xs: "18px", sm: "22px" }}
                  width={{ xs: "100%", sm: "100%" }}
                  textAlign="center"
                >
                  Claimed Reward
                  <Box
                    color="#FFE201"
                    fontWeight={900}
                    fontSize={{ xs: "18px", sm: "22px" }}
                    textAlign="center"
                  >
                    {parseInt(+reward).toLocaleString({})}
                  </Box>
                </Box>

                <Box
                  fontWeight={700}
                  color="#FF9602"
                  fontSize={{ xs: "18px", sm: "22px" }}
                  textAlign="center"
                  width={{ xs: "100%", sm: "100%" }}
                >
                  Current Staked
                  <Box
                    color="#FFE201"
                    fontWeight={900}
                    fontSize={{ xs: "18px", sm: "22px" }}
                    textAlign="center"
                  >
                    {/* {(+currentStaked).toFixed(0)} */}
                    {parseInt(+currentStaked).toLocaleString()}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              width={{ xs: "100%", sm: "45%" }}
              height="405px"
              mx="auto"
              borderRadius="20px"
              p={2}
              my={2}
            >
              <Box
                bgcolor="#000000"
                borderRadius="20px"
                width="100%"
                height="45%"
                p={4}
                mb={3}
              >
                <Box
                  fontWeight={700}
                  color="#FF9602"
                  fontSize={{ xs: "18px", sm: "22px" }}
                  textAlign="center"
                >
                  Your Wolverinu Wallet Balance
                </Box>
                <Box
                  color="#FFE201"
                  fontWeight={900}
                  fontSize={{ xs: "18px", sm: "25px" }}
                  textAlign="center"
                >
                  {+balance.toFixed(2).toLocaleString()}
                </Box>
              </Box>
              <Box
                bgcolor="#000000"
                height="50%"
                borderRadius="20px"
                width="100%"
                // display="flex"
                alignItems="center"
                // justifyContent="space-between"
                p={2}
              >
                <Box
                  fontWeight={700}
                  color="#FF9602"
                  fontSize={{ xs: "18px", sm: "22px" }}
                  width={{ xs: "100%", sm: "100%" }}
                  textAlign="center"
                >
                  Total Staked
                  <Box
                    color="#FFE201"
                    fontWeight={900}
                    fontSize={{ xs: "18px", sm: "25px" }}
                    textAlign="center"
                  >
                    {parseInt(+staked).toLocaleString({})}
                  </Box>
                </Box>
                <Box
                  fontWeight={700}
                  color="#FF9602"
                  fontSize={{ xs: "18px", sm: "22px" }}
                  textAlign="center"
                  width={{ xs: "100%", sm: "100%" }}
                >
                  Total UnStaked
                  <Box
                    color="#FFE201"
                    fontWeight={900}
                    fontSize={{ xs: "18px", sm: "25px" }}
                    textAlign="center"
                  >
                    {+unstaked ? (+unstaked).toFixed(0) : 0}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
