import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Slide,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import Loading from "./loading";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  metaMaskUpperLayer: {
    width: "367px",
    height: "95px",
    border: "4px solid #190141b1",
    background: "#fff",
    borderRadius: "50px",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    margin: "20px 10px",
    paddingRight: "30px",
    paddingLeft: "30px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: { width: "100%" },
  },
  buttonText: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "17.69pt",
    color: "#05217C",
    [theme.breakpoints.down("xs")]: { fontSize: "15px" },
  },
}));
function UnstakeModal({ open, setOpen, unstakeHandler, loading }) {
  const classes = useStyles();
  const { deactivate } = useWeb3React();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div className="modal__main__container">
        <Dialog
          open={open}
          keepMounted
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className="dialoge__content__section">
            <Loading loading={loading} />

            <Box component="h3" fontSize="30px">
              <span style={{ color: "red" }}> Warning!</span> You will not
              receive any reward as well as 5% tokens will be deducted.
            </Box>
            <Box
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              p={1}
            >
              <Box onClick={handleClose}>
                <button
                  style={{
                    background: "#E51F34",
                    borderRadius: "14px",
                    color: "white",
                    padding: "10px 40px",
                    fontSize: "18px",
                    border: "none",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </Box>
              <Box>
                <button
                  style={{
                    background: "#E51F34",
                    borderRadius: "14px",
                    color: "white",
                    padding: "10px 40px",
                    fontSize: "18px",
                    border: "none",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={unstakeHandler}
                >
                  UnStake
                </button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
        {/* </Slide> */}
      </div>
    </div>
  );
}

export default UnstakeModal;
