import { Box, Container, useMediaQuery } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import moment from "moment";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useStakingContract, useTokenContract } from "../hooks";
import poolCard from "../images/poolcard.png";
import Loading from "./loading";
import UnstakeModal from "./unstakeModal";
export default function Statistics({ stakeDetails, init, init1 }) {
  const { account, active } = useWeb3React();
  const StakingContract = useStakingContract();
  const [loading, setloading] = useState(false);
  const [open, setopen] = useState(false);
  const [index, setindex] = useState("0");
  const matches = useMediaQuery("(max-width:960px)");

  const unstakeHandler = async () => {
    try {
      setloading(true);
      const tx = await StakingContract.unstake(index.toString());
      await tx.wait();
      toast.success("Success! Amount successfully unsataked.");
      init();
      init1();
      setopen(false);
      setloading(false);
    } catch (error) {
      if (error?.data?.message) {
        toast.error(error?.data?.message);
      } else {
        toast.error(error?.message);
      }
      setopen(false);
      setloading(false);
    }
  };
  const claimHandler = async (index) => {
    try {
      setloading(true);
      const tx = await StakingContract.withdraw(index.toString());
      await tx.wait();
      toast.success("Success! Amount successfully claimed.");
      init();
      init1();
      setloading(false);
    } catch (error) {
      if (error?.data?.message) {
        toast.error(error?.data?.message);
      } else {
        toast.error(error?.message);
      }
      setloading(false);
    }
  };
  return (
    <Box position="relative">
      <UnstakeModal
        open={open}
        loading={loading}
        setOpen={setopen}
        unstakeHandler={unstakeHandler}
      />

      <Container maxWidth="lg">
        <Box
          component="h3"
          borderRadius={{ xs: "20px", sm: "59px" }}
          align="cneter"
          width={{ xs: "100%", sm: "100%", md: "100%" }}
          mx="auto"
          mt={10}
          p={{ xs: 2, sm: 5 }}
          boxShadow=" 0px 0px 2px 2px #B600D3"
          style={{
            backgroundImage: "linear-gradient(225deg, #034282, #ff8b03)",
          }}
        >
          <Box align="center" my={1}>
            <img
              src="logo.svg"
              alt=""
              style={{ width: "auto", height: "100px", paddingBottom: "2rem" }}
            />
          </Box>
          <Box width="100%" mx="auto" p={1} my={0} align="textAligncenter">
            <Box
              display="flex"
              bgcolor="rgba(250, 247, 247, 0.02)"
              color="#fff"
              alignItems="center"
              p={2}
              fontSize={{ xs: "12px", sm: "20px" }}
              fontWeight={700}
            >
              <Box width="30%" p={2}>
                <Box fontWeight={400} textAlign="center">
                  Staked Amount
                </Box>
              </Box>
              <Box width="30%" p={2}>
                <Box fontWeight={400} textAlign="center">
                  Withdrawal Time
                </Box>
              </Box>
              {/* <Box width="25%" p={2}>
                <Box fontWeight={400} textAlign="left">
                  UnStake
                </Box>
              </Box> */}
              <Box width="30%" p={2}>
                <Box fontWeight={400} textAlign="center">
                  Claim
                </Box>
              </Box>
            </Box>
            {stakeDetails?.length !== 0 ? (
              stakeDetails.map(
                ({ time, amount, unstaked, withdrawan }, index) => (
                  <Box
                    display="flex"
                    bgcolor="rgba(255, 255, 255, 0.05)"
                    alignItems="center"
                    p={2}
                    key={index}
                    fontSize={{ xs: "10px", sm: "18px" }}
                  >
                    <Box width="30%" p={2}>
                      <Box fontWeight={400} textAlign="center">
                        {(+amount).toFixed(0)}
                      </Box>
                    </Box>
                    <Box width="30%" p={2}>
                      <Box fontWeight={400} textAlign="center">
                        {moment.unix(+time).format("LLL")}
                      </Box>
                    </Box>

                    <Box width="30%" align="center" p={2}>
                      <button
                        disabled={
                          +time > +moment().format("x") / 1000 || withdrawan
                            ? true
                            : false
                        }
                        style={{
                          background: "#FF9803",
                          boxShadow: "rgb(182 0 211 / 70%) 0px 0px 13px",
                          borderRadius: "14px",
                          color:
                            +time > +moment().format("x") / 1000 || withdrawan
                              ? "black"
                              : "white",
                          padding: matches ? "10px 10px" : "10px 40px",
                          fontSize: matches ? "12px" : "18px",
                          border: "none",
                          cursor:
                            +time > +moment().format("x") / 1000 || withdrawan
                              ? "no-drop"
                              : "pointer",
                        }}
                        onClick={() => claimHandler(index)}
                      >
                        Claim
                      </button>
                    </Box>
                  </Box>
                )
              )
            ) : (
              <Box
                display="flex"
                bgcolor="rgba(255, 255, 255, 0.05)"
                alignItems="center"
                p={2}
                key={index}
                fontSize={{ xs: "10px", sm: "18px" }}
              >
                <Box width="30%" p={2}>
                  <Box fontWeight={400} textAlign="center">
                    0
                  </Box>
                </Box>
                <Box width="30%" p={2}>
                  <Box fontWeight={400} textAlign="center">
                    0
                  </Box>
                </Box>

                <Box width="30%" p={2}>
                  <Box fontWeight={400} textAlign="center">
                    0{" "}
                  </Box>
                </Box>
              </Box>

              // <Box
              //   display="flex"
              //   bgcolor="rgba(255, 255, 255, 0.05)"
              //   alignItems="center"
              //   p={2}
              //   fontSize={{ xs: "10px", sm: "18px" }}
              // >
              //   <Box width="100%" p={2}>
              //     <Box fontWeight={400} textAlign="center">
              //       You have no stake record yet.
              //     </Box>
              //   </Box>
              // </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
