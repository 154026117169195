import React from "react";
import { Contract } from "@ethersproject/contracts";
import { useWeb3React } from "@web3-react/core";
import tokenAbi from "./utils/tokenAbi.json";
import stakingAbi from "./utils/stakingAbi.json";
import { tokenAddress, stakingAddress } from "./utils/environment";
import Web3 from "web3";

const web3 = new Web3(
  Web3.givenProvider
    ? Web3.givenProvider
    : "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
);

function useContract(address, ABI) {
  const { active, error, library } = useWeb3React();
  return React.useMemo(() => {
    if (active && !error && library) {
      const signer = library.getSigner();
      return new Contract(address, ABI, signer);
    } else {
      return null;
    }
  }, [address, ABI, active, error, library]);
}

export function useTokenContract() {
  return useContract(tokenAddress, tokenAbi);
}
// export function usePriceContract() {
//   try {
//     const PriceContract = new web3.eth.Contract(priceAbi, priceAddress);
//     return PriceContract;
//   } catch (error) {}
// }
export function useStakingContract() {
  return useContract(stakingAddress, stakingAbi);
}

export function useStakingContract1() {
  try {
    const StakingContract = new web3.eth.Contract(stakingAbi, stakingAddress);
    return StakingContract;
  } catch (error) {}
}
export function useTokenContract1() {
  try {
    const TokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
    return TokenContract;
  } catch (error) {}
}
