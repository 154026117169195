import logo from "./logo.svg";
import React, { useState, useEffect } from "react";
import "./App.css";
// stakingAddress

import {
  useTokenContract,
  useStakingContract,
  useStakingContract1,
  useTokenContract1,
  // useStakingContract1,
  // useTokenContract1,
  // usePriceContract,
} from "./hooks";

import { useWeb3React } from "@web3-react/core";
import Home from "./components/home";
import Tiers from "./components/tiers";
import StakingLeader from "./components/staking-leaderboard";
import Statistics from "./components/statistics";
import Summary from "./components/summary";
import { stakingAddress } from "./utils/environment";
function App() {
  const [stakeDetails, setstakeDetails] = useState([]);
  const StakingContract = useStakingContract();
  // const priceContract = usePriceContract();

  // const tokenContract = useTokenContract();
  const { account, active } = useWeb3React();
  const [staked, setstaked] = useState(0);
  const [unstaked, setunstaked] = useState(0);
  const [withdrwan, setwithdrwan] = useState(0);
  const [reward, setreward] = useState(0);
  const [currentStaked, setcurrentStaked] = useState(0);
  const [earned, setearned] = useState(0);
  const [balance, setbalance] = useState(0);
  const [totalStakers, settotalStakers] = useState(0);
  const [totalstaked, settotalstaked] = useState(0);
  const [Apy, setApy] = useState(0);
  const StakingContract1 = useStakingContract1();
  const tokenContract1 = useTokenContract1();
  const tokenContract = useTokenContract();
  const startTime = () => {
    setInterval(async () => {
      const dec = +(await tokenContract.decimals());
      const decimals = +Math.pow(10, dec);
      // const reward = await StakingContract.realtimeReward(account);
      // setearned(decimals.toFixed(4));
    }, 1000);
  };
  const init1 = async () => {
    try {
      if (StakingContract1 && tokenContract1) {
        const dec = await tokenContract1.methods.decimals().call();
        const decimals = +Math.pow(10, +dec);
        const totalStaked = await StakingContract1.methods.totalStaked().call();
        const totalstakers = await StakingContract1.methods
          .totalUniqueStakers()
          .call();
        // const { _reserve0, _reserve1 } = await priceContract.methods
        //   .getReserves()
        //   .call();
        // var reserv = +_reserve0 / +_reserve1;
        // setApy(+totalStaked * reserv);
        settotalstaked(+totalStaked / +decimals);
        settotalStakers(+totalstakers);
      } else {
        const dec = await tokenContract.decimals();
        const decimals = +Math.pow(10, +dec);
        const totalStaked = await StakingContract.totalStaked();

        const totalstakers = await StakingContract.totalUniqueStakers();
        // const { _reserve0, _reserve1 } = await priceContract.methods
        //   .getReserves()
        //   .call();
        // var reserv = +_reserve0 / +_reserve1;
        // setApy(+totalStaked * reserv);
        settotalstaked(+totalStaked / +decimals);
        settotalStakers(+totalstakers);
      }
    } catch (e) {}
  };

  const init = async () => {
    const dec = +(await tokenContract.decimals());
    const decimals = +Math.pow(10, dec);
    const { totalstakeduser, claimedstakeTokens, unStakedTokens, stakecount } =
      await StakingContract.users(account);

    const balance = +(await tokenContract.balanceOf(account)) / decimals;
    setbalance(balance);
    setstaked(+totalstakeduser / decimals);
    setwithdrwan(+claimedstakeTokens / decimals);
    setreward(+claimedstakeTokens / decimals);
    const result = +claimedstakeTokens + +unStakedTokens;
    setunstaked(+result / decimals);
    if (+stakecount > 0) {
      let arr = [];
      let currentstaked = 0;
      for (let i = 0; i < +stakecount; i++) {
        const { amount, bonus, withdrawan, unstaked, plan, withdrawTime } =
          await StakingContract.stakedetails(account, i.toString());
        setearned(+bonus / +decimals);
        const obj = {
          time: +withdrawTime,
          amount: +amount / +decimals,
          withdrawan: withdrawan,
          unstaked: unstaked,
        };

        if (!unstaked && !withdrawan) {
          currentstaked += +amount / +decimals;
        }
        arr.push(obj);
      }
      setcurrentStaked(currentstaked);
      setstakeDetails([...arr]);
    }
  };
  useEffect(() => {
    if (StakingContract && account && tokenContract) {
      init();
      startTime();
    }
    if (
      (StakingContract && tokenContract) ||
      (StakingContract1 && tokenContract1)
    ) {
      init1();
    }
  }, [
    StakingContract,
    tokenContract,
    account,
    StakingContract1,
    tokenContract1,
  ]);

  return (
    <>
      <Home totalStakers={totalStakers} totalstaked={totalstaked} Apy={Apy} />
      <Tiers init={init} init1={init1} />
      <Summary
        staked={staked}
        unstaked={unstaked}
        withdrwan={withdrwan}
        reward={reward}
        currentStaked={currentStaked}
        earned={earned}
        balance={balance}
      />
      <Statistics stakeDetails={stakeDetails} init={init} init1={init1} />
      {/* <StakingLeader /> */}
    </>
  );
}

export default App;
