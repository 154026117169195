import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  InviteContainer: {
    position: "relative",
    bottom: "10rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "5rem",
      bottom: "8rem",
    }
  },
  InviteH: {
    ...theme.font,
    fontWeight: 900,
    color: "#262261",

  },
  Invitep: {
    ...theme.font,
    fontSize: "1.5rem",
    fontWeight: 500,
    color: "#262261",
    letterSpacing: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem"
    }
  },
  InviteF: {
    background: theme.palette.common.btngradient,
    padding: ".2rem 2rem",
    borderRadius: 30,
    fontFamily: 'Teko',
    color: 'white',
    letterSpacing: 3,
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "3rem"
    }
  },
  aikicoin: {
    width: "16rem",
    height: "15rem",
    // marginTop: "rem",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "8rem",
      marginTop: "1.3rem"
    }
  },
  aikicointwo: {
    width: "16rem",
    height: "16em",
    marginTop: "6rem",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "10rem",
      marginTop: "1.3rem"
    }
  },
  timerbox: {
    background: "#5E5EBF",
    width: "auto",
    height: "auto",
    margin: "10px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    // border: "2px solid",
    // borderImageSource: "linear-gradient(140deg, #692877, #C16C1C)",
    // borderImageSlice: "1",
    padding: ".5rem 1.5rem",
    [theme.breakpoints.down("md")]: {
      padding: ".05rem .6rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: ".05rem .3rem",
    }

  },
  countdown: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "40px",
    width: "140%",
    height: "auto",
    textAlign: "center",
    backgroundColor: "#5238BD",
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      width: "auto",
    }
  },
  countdownTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    letterSpacing: "1.425px",
    color: "#ffffff"
  },
  airdroppresale: {
    ...theme.font,
    paddingTop: ".5rem",
    fontSize: "1.5rem",
    fontWeight: 600,
    color: "#262261",
    letterSpacing: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    }
  },
  timerContainer: {
    padding: "3rem",
    widht: "60%",
    height: "auto",
    border: " 2px solid  #D90EE8"
  }

}))



const LaunchApp = () => {
  const classes = useStyles();
  const [countTime, setCountDateTime] = React.useState({
    time_days: 0,
    time_Hours: 0,
    time_Minusts: 0,
    time_seconds: 0,
  });
  let interval = React.useRef();

  const contStartTimerStart = () => {

    // let countDownDate = moment("oct 1,2021 00:00:00").format("x")
    var countDownDate = new Date("Sept 30, 2021 17:00:00").getTime();
    // .add(10, "hours")
    ;
    interval = setInterval(() => {
      // const now = new Date().getTime();
      // var jun = moment();
      // let a = jun.tz("America/Jamaica").format("x");
      var now = new Date();
      var utc = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
      const distance = countDownDate - utc;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));;
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minuts = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setCountDateTime({
          ...countTime,
          time_days: days,
          time_Hours: hours,
          time_Minusts: minuts,
          time_seconds: seconds,
        });
      }
    }, 1000);
  };

  React.useEffect(() => {
    contStartTimerStart();
  }, []);
  return (

    <Container>
      <Box pt={20} >
        <Box className={classes.timerContainer}>
          <Grid align="center"><Typography className={classes.airdroppresale} variant="h2">Hey! This is Wolverinu decentralized launchpad. I am going live on BSC mainnet on 30th September.</Typography>  </Grid>
          <Grid container justifyContent='center' >
            <Grid item>
              <Box className={classes.timerbox}>
                <span className={classes.countdown}>{countTime.time_days < 10 ? `0${countTime.time_days}` : countTime.time_days}</span>
                <span className={classes.countdownTitle}>Days</span>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.timerbox}>
                <span className={classes.countdown}>{countTime.time_Hours < 10 ? `0${countTime.time_Hours}` : countTime.time_Hours}</span>
                <span className={classes.countdownTitle}>Hours</span>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.timerbox}>
                <span className={classes.countdown}>{countTime.time_Minusts < 10 ? `0${countTime.time_Minusts}` : countTime.time_Minusts}</span>
                <span className={classes.countdownTitle}>Minutes</span>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.timerbox}>
                <span className={classes.countdown}>{countTime.time_seconds < 10 ? `0${countTime.time_seconds}` : countTime.time_seconds}</span>
                <span className={classes.countdownTitle}>Seconds</span>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* <div class="launchApp taC">
          <h2>Hey! This is Wolverinu decentralized launchpad. I am going live on BSC mainnet on 30th September.</h2>
          <div id="countdown" class="dFA jcC countdown">
            <div>
              <span class="days">0</span>
              <div class="smalltext">Days</div>
            </div>
            <div>
              <span class="days">0</span>
              <div class="smalltext">Hours</div>
            </div>
            <div>
              <span class="days">0</span>
              <div class="smalltext">Minutes</div>
            </div>
            <div>
              <span class="days">0</span>
              <div class="smalltext">Seconds</div>
            </div>
          </div>
        </div> */}
      </Box>
    </Container>


  )
}

export default LaunchApp
